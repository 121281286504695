<template>
  <div class="layout__wrapper">
    <div id="layout" ref="layout" class="layout__container">
      <div v-if="width <= t_lg && isShowMenu" class="layout__menu">
        <MobileMenu v-on:hideMenu="hideMenu" @mousedown="e => e.preventDefault()" />
      </div>
      <div class="layout__subheader">
        <div class="layout__subheader_container">
          <div :class="setHeaderClass()">
            <Header v-on:showMenu="showMenu" />
          </div>
        </div>
      </div>
      <div class="page__wrapper" v-if="!pageLoading" >
        <slot name="page" :scroll-top="scrollTop"/>
      </div>
      <div class="page__loader" v-if="pageLoading">
        <Loader />
      </div>
      <Footer v-if="!companyId"/>
    </div>
  </div>
</template>

<script>
const Header = () => import('@/components/header/Header.vue');
const Footer = () => import('@/components/footer/Footer.vue');
const MobileMenu = () => import('@/components/mobileMenu/MobileMenu.vue');
const Loader = () => import('@/components/loader/Loader.vue');

export default {
  name: 'PageLayout',
  data() {
    return {
      isShowMenu: false,
      showHeader: true,
      lastScrollTop: 0,
      scrollTop: 0,
      pageLoading: false,
    };
  },
  watch: {
    '$route.path': function () {
      this.$refs.layout.scrollTop = 0;
    },
    showHeader: function (val) {
      this.$store.commit('index/setShowHeader', val);
    },
  },
  computed: {
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    companyId() {
      return this.$store.state.index.companyId;
    },
  },
  methods: {
    handleScroll(e) {
      this.$store.commit('index/setScroll', e.target.scrollTop);
      this.scrollTop = e.target.scrollTop;
      const st = e.target.scrollTop;
      if (st > this.lastScrollTop) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      this.lastScrollTop = st <= 0 ? 0 : st;
    },
    setHeaderClass() {
      if (!this.showHeader) {
        return 'layout__header layout__header_hide';
      }
      return 'layout__header';
    },
    showMenu() {
      this.isShowMenu = true;
    },
    hideMenu() {
      this.isShowMenu = false;
    },
  },
  mounted() {
    const layout = document.getElementById('layout');
    layout.addEventListener('scroll', this.handleScroll, { passive: true });
    this.$store.watch((state) => state.index.pageLoading, () => {
      this.pageLoading = this.$store.state.index.pageLoading;
    });
  },
  components: {
    Header,
    Footer,
    MobileMenu,
    Loader,
  },
};
</script>
